import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import blogs from "../src/components/blog-data/blogs.json";
import stories from "./components/portflio-data/data.json";
import serviceData from "./components/service-detail/servicedata.json"
import Loader from './components/Loader';
const HomeV1 = React.lazy(() => import('./components/home-v1'));
const About = React.lazy(() => import('./components/about'));
const Service = React.lazy(() => import('./components/service'));
const ServiceDetails = React.lazy(() => import('./components/service-details'));
const PortfolioFull = React.lazy(() => import('./components/portfolio-full'));
const PortfolioDetails = React.lazy(() => import('./components/portfolio-details'));
const Pricing = React.lazy(() => import('./components/pricing'));
const BlogList = React.lazy(() => import('./components/blog-list'));
const BlogDetails = React.lazy(() => import('./components/blog-details'));
const Contact = React.lazy(() => import('./components/contact'));
class Root extends Component {
	render() {
		return (
			<Router>
				<Suspense fallback={<Loader></Loader>}>
					<Switch>
						<Route exact path="/" component={HomeV1} />
						{/* <Route exact path="/home-v2" component={HomeV2} />
							<Route exact path="/home-v3" component={HomeV3} />
							<Route exact path="/home-v4" component={HomeV4} /> */}
						<Route path="/about" component={About} />
						<Route path="/service" component={Service} />
						{/* <Route path="/service-v2" component={ServiceV2} /> */}
						{
							serviceData.map((service, index) => (
								<Route key={index} path={`/service-details/${service['service-id']}`} component={ServiceDetails} />
							))
						}
						
						{/* <Route path="/portfolio-standard" component={PortfolioStandard} /> */}
						<Route path="/portfolio" component={PortfolioFull} />
						{/* <Route path="/portfolio-masonary" component={PortfolioMasonary} /> */}
						{
							stories.map((story, index) => (
								<Route key={index} path={`/portfolio-details/${story.story_id}`} component={PortfolioDetails} />
							))
						}
						<Route path="/pricing" component={Pricing} />
						{/* <Route path="/faq" component={Faq} />
							<Route path="/testimonial" component={Testimonial} />
							<Route path="/team" component={Team} /> */}
						{/* <Route path="/blogs" component={BlogGrid} /> */}
						<Route path="/blog-list" component={BlogList} />
						{
							blogs.map((blog, index) => (
								<Route key={index} path={`/blog-details/${blog.blogId}`} component={BlogDetails} />
							))
						}
						<Route path="/contact" component={Contact} />
					</Switch>
				</Suspense>
			</Router>
		);
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('react-root'));