import React from 'react'

function Loader () {
  let publicUrl = process.env.PUBLIC_URL + '/'
  let imagealt = 'image'
  return (
    <div className='preloader' style={{zIndex:"999999999999999999999"}}>
      <img src={publicUrl + 'assets/images/frixum/preloader.png'} alt={imagealt} className='preloader__image' />
    </div>
  )
}

export default Loader
